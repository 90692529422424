<template>
  <table>
    <thead>
      <tr>
        <th class="first-th"><span></span><strong></strong></th>
        <th>
          <span>{{ day }}</span
          ><strong></strong>
        </th>
      </tr>
    </thead>
    <tbody v-html="tableCalendar"></tbody>
  </table>
</template>

<script>
export default {
  name: "CalendarDay",
  props: {
    day: {
      type: String,
      default: "",
    },
    tableCalendar: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.bg_yellow_ver2 {
  text-align: left !important;
}
.first-th {
  width: 1% !important;
}
</style>
